
<template>
<div class="container-fluid mt-3">
                <v-alert
                v-if="error"
                prominent
                border="left"
                color="warning"
                outlined
              >
                <v-row align="center">
                  <v-col>
                    {{this.error.message}}
                  </v-col>
                </v-row>
              </v-alert>
              <v-alert
                v-if="data"
                prominent
                border="left"
                color="info"
                outlined
              >
                <v-row align="center">
                  <v-col>
                    {{this.data.message}}
                  </v-col>
                </v-row>
              </v-alert>
              <div v-if="order" class="tw-flex tw-items-center tw-space-x-4">
              <div v-if="!order.payment_declined_by_user && !order.rejected && order.is_approved && !order.payment_authorized_by_user">
                <button
                      @click="authorize(true)" :disabled="paymentProcessing"
                      
      class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium 
      tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 
      focus:tw-ring-offset-2 focus:tw-ring-black tw-items-center">
            

                  {{$t('booking.authorize')}}
                </button>
                <button
                      @click="authorize(false)" :disabled="paymentProcessing"
                                        class="tw-mt-6 tw-ml-3 tw-px-6 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-red focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">

                  {{$t('booking.decline')}}
                </button>

              </div>
              </div>
      <ViewBooking v-if="this.order" :item=this.order></ViewBooking>


</div>
</template>


<script>

import ViewBooking from '../../../components/ViewBooking.vue'
import { mapGetters } from 'vuex'

//import { DollarSignIcon,XCircleIcon   } from "vue-feather-icons";
import i18n from '@/i18n'

const qs = (params) => Object.keys(params).map(key => `${key}=${params[key]}`).join('&')

export default {
  middleware: ['auth','user'],
  components:{
    ViewBooking,
    //DollarSignIcon,
    //XCircleIcon 
  },
  async beforeRouteEnter (to, from, next) {
    try { 

         if(qs(to.query)){
          const { data } = await this.$http.post(`/api/orders/${to.params.id}/payment/verify?${qs(to.query)}`)
          next( vm => {
            vm.success = data.message,
            vm.paymentUrl = data.url
          });
        }else{
            next();
        }
      
      
    } catch (e) {
      console.log(e.data.message);
      next( vm => { vm.error = e.data.message })
    }
  },
  computed:{
    ...mapGetters({
      user: 'auth/user'
    }),
  },
  metaInfo() {
    return {
      title: i18n.t('my_order'),
    }
  },
  data () {
      return {
        loading: false,
        order: null,
        data: null,
        paymentUrl: null,
        success: '',
        error: '',
        paymentProceed: false,
        paymentProcessing: false,
      }
    },
    mounted() {
     this.getItem();
    },
    watch: {
    },    
    methods: {
      authorize(param) {
        this.$toast.question('Are you sure about that?', "Confirm", {
          timeout: 20000,
          close: false,
          overlay: true,
          displayMode: 'once',
          id: 'address',
          zindex: 999,
          title: 'Hey',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', (instance, toast) => {

              this.authorizeOrder(param);

              instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

            }, true],
            ['<button>NO</button>', function (instance, toast) {

              instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

            }],
          ]
        });
      },      
      async authorizeOrder(param){
        this.paymentProcessing = true;
        this.loading = true;

        let uri = this.paymentUrl;
        //console.log(uri);
        await this.$http.post(uri,{authorize: param}).then((response) => {
            this.data = response.data,
            this.loading = false
            this.getItem();
        }).catch((error) => {
              this.loading = false;
              this.error = error.data;
        });

      },
      print(item){
        //console.log(item);
        this.selectedItem = item;
        this.showPrint = true;
      },
      getColor (satus) {
        if (satus === 'Open') return 'cyan'
        else if (satus === 'Offered') return 'orange'
        else if (satus === 'Accepted') return 'green'
        else return 'red'
      },
      getColorClass (satus) {
        if (satus === 'Open') return 'yellow--text'
        else if (satus === 'Approved') return 'black--text'
        else if (satus === 'Pending') return 'orange--text'
         else if (satus === 'Processed') return 'blue--text'
          else if (satus === 'Delivered') return 'green--text'
        else return 'red--text'
      },
      getItem(){
        this.loading = true;

        let uri = 'api/orders/'+this.$route.params.id;
        this.$http.get(uri).then((response) => {
            this.order = response.data.data,
            this.loading = false
        });
      },

    },

}
</script>







